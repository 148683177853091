<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template>
        <div v-for="(toast, key) in toasts" :key="'toast' + key">
          <CToast :color="toast.color" v-bind="toast"  :show="true">
            {{toast.content}}
          </CToast>
        </div>        
      </template>
    </CToaster>
  </div>
</template>
<script>
import router from '../router/index'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import store from '../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../services/general';

export default {
  name: 'TheAdminContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Loading
  },
  data() {
      return {
        is_administrator: false,
        is_collaborator: false,
        show_menu: false
      }
  },
  mounted: async function() {
    this.is_administrator = !!localStorage.getItem("is_administrator");
    this.is_collaborator = !!localStorage.getItem("is_collaborator");

    if(this.is_administrator+"" !== "true"){
        localStorage.removeItem("token");
        store.commit('clear');
        router.push("login");
        return;
    }
    
  }, 
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
